section.contact {
  margin-bottom: 10%;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 10%;
  font-family: 'Roboto Slab', serif;
  color: #0D2E5B;
  margin-right: 0;
  width: 200%;
  
}
a.resume {
  font-family: "Roboto Slab", serif;
  color: #0d2e5b;
  font-weight: 900;
  margin-bottom: 10%;
  text-decoration: underline;
}


  h1{
    margin-bottom: 4%;
    font-family: "Roboto Slab", serif;
    color: #0d2e5b;
  }

  a.github {
    padding: 6px;
    font-size: 30px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.facebook {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.pinterest{
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.instagram {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.linkedIn {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }
  
  a:hover{
    opacity: 0.7;
  }

  a.resume{
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }