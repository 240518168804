.center {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
  
  .lineDivider{
    width: 80%;
    height: 3%;
    
  }