.Rachel {
    width: 95%;
    height: auto;
    float: left;
    margin: 0px 0px 10px 0px;
  }
  
 a.github {
    padding: 6px;
    font-size: 30px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.facebook {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.pinterest{
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.instagram {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }

  a.linkedIn {
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }
  
  a:hover{
    opacity: 0.7;
  }

  .image{
   margin-right: 20px;
  }

  a.resume{
    padding: 6px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: #0D2E5B;
  }