.navbar {
  background-color: #0d2e5b;
  color: #fff;
  position: sticky;
  /* position: fixed;
      width: 100%;
      top: 0; */
}

/* .mr-auto{
    color: white;
   } */

/* button .navbar-toggler {
  color: white;
} */

/* .navbar-light.brand {
    color: white;
    font-family: 'Rock Salt', cursive;
  } */

.navbar-dark .navbar-brand {
  color: white;
  font-family: "Rock Salt", cursive;
}

#basic-navbar-nav {
  color: white;
}

.navbar-toggler {
  color: white;
}

nav.navbar.navbar-expand-lg.navbar-brand {
  color: white;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .sectionSpacing {
  padding-top: 60px;
  height: 50%;
}

/* a{
    background-color: #0D2E5B;
    color: white;
    text-align: center;
} */

.nav-item .nav-link {
  background-color: #0d2e5b;
  color: white;
  text-align: center;
}

a.nav-link.active{
  color: white
}

a.nav-link{
  color: #fff;
}


.navbar-nav {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  float: right;
  position: absolute;
  right: 0px;
  width: 300px;
}

.nav-item .nav-link {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  
}

.navbar.navbar-light {
  color: #fff;
  
}

button .navbar-toggler .navbar-toggler-icon{
  color: #fff;
}
div.mr-auto.smooth-scroll.navbar-nav{
  background-color: #0d2e5b;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}