.wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;

  }
  .row {
    width: 800px;
}
  .portStyle {
    width: 100%;
    height: 320px;
    border-radius: 15px;
    padding: 1.5rem;
    background: #fff;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
    margin-right: 5%;
    margin-bottom: 30px;
    color: white;
  }

  @media (max-width: 991px) {
    .portStyle {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 400px) {
    .portStyle {
      height: 315px;
      width: 150%;
      margin-bottom: 40px;
    }
  }
  .portStyle:hover {
    transform: translateY(20px);
  }
  .portStyle:hover:before {
    opacity: 1;
  }
  .portStyle:hover .info {
    opacity: 1;
    transform: translateY(0px);
  }
  .portStyle:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0,0,0,0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  .portStyle img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  
  .portStyle .info {
    position: relative;
    z-index: 3;
    color: #fff;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
  }
  .portStyle .info h1 {
    margin: 0px;
    color: #fff;
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
  }
  .portStyle .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
  }
  .portStyle .info button .btn a #liveButton #codeButton{
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: #fff;
    color: #000;
    font-weight: 400;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .card .info a:hover {
    background: #1e90ff;
    color: #fff;
  }

  h1{
    color: white;
    font-family: 'Roboto Slab', serif;
    
  }
  
  div.info p a {
    color: white;
    font-family: 'Montserrat', sans-serif;
  }

  a.btn {
   background-color:  #0D2E5B;
   border-color:  #0D2E5B;;
   margin-right: 4%;
  }
  


